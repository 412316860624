const nl = {
    'actions': {
        'add': 'Toevoegen',
        'actions': 'Acties',
        'cobra': 'Cobra',
        'close': 'Sluiten',
        'closeweek': 'Weekstaat indienen',
        'delete': 'Verwijderen',
        'details': 'Details',
        'download': 'Downloaden',
        'edit': 'Bewerken',
        'export': 'Exporteren',
        'login': 'Inloggen',
        'logout': 'Uitloggen',
        'next': 'Volgende',
        'previous': 'Vorige',
        'ok': 'Ok',
        'save': 'Opslaan',
        'search': 'Zoeken',
        'submit': 'Versturen',
        'archive': 'Archiveren',
        'createinvoice': 'Factureren',
        'activate': 'Activeren',
        'reactivate': 'Heractiveren',
        'deactivate': 'Loskoppelen',
        'merge': 'Samenvoegen',
        'cancel': 'Annuleren',
        'synccarerix': 'Sync Carerix',
        'synctimechimp': 'Sync TimeChimp',
        'refresh': 'Bijwerken',
        'sendinvite': 'Uitnodiging sturen',
        'reject': 'Afwijzen',
        'reopenweek': 'Heropenen',
        'approve': 'Goedkeuren',
        'send': 'Verstuur mail',
        'share': 'Delen',
        'syncprojects': 'Synchroniseer projecten'
    },
    'confirm': {
        'leaveWithoutSave': '<strong>Weet u het zeker?</strong><br />Er zijn niet opgeslagen wijzigingen',
        'delete': '<strong>Weet u het zeker?</strong><br />Record verwijderen',
        'archive': '<strong>Weet u het zeker?</strong><br />Archiveer record',
        'merge': '<strong>Weet u het zeker?</strong><br />Records samenvoegen',
        'disapprove': '<strong>Weet u het zeker?</strong><br />Inkoopfactuur afkeuren',
        'approve': '<strong>Weet u het zeker?</strong><br />Inkoopfactuur goedkeuren',
        'closeweek': '<strong>Weet u het zeker?</strong><br />Weekstaat indienen',
    },
    'error': {
        'saveChanges': 'Er is een probleem opgetreden tijdens het opslaan van de gegevens',
        'deleteRecordConflict': 'Dit record kan niet worden verwijderd',
        'emailexists': 'Dit e-mailadres is al in gebruik',
        'emailnotfound': 'E-mail niet gevonden',
        'mergeCompanies': '1 geselecteerd bedrijf moet bekend zijn in Carerix en 1 geselecteerd bedrijf moet bekend zijn in TimeChimp om te kunnen samenvoegen',
        'mergeEmployees': '1 geselecteerde medewerker moet bekend zijn in Carerix en 1 geselecteerde medewerker moet bekend zijn in TimeChimp om te kunnen samenvoegen',
        'merge': 'Er is een probleem opgetreden tijdens het samenvoegen',
        'createinvoice': 'Er is een probleem opgetreden tijdens het maken van de factuur',
        'synccarerix': 'Fout in sync met Carerix',
        'synctimechimp': 'Fout in sync met TimeChimp',
        'disapprove': 'Er is een fout opgetreden tijdens het afkeuren van de factuur',
        'approve': 'Er is een fout opgetreden tijdens het goedkeuren van de factuur',
        'timezero': 'Verlofuren moeten groter zijn dan nul.',

    },
    'format': {
        'date': 'DD-MM-YYYY',
        'dateshort': 'DD-MM',
        'dateTime': 'DD-MM-YYYY HH:mm',
        'dayanddate': 'dd DD-MM-YYYY',
        'time': 'HH:mm',
        'timezone': 'UTC',
        'daymonth': 'D MMMM'
    },
    'path': {

        'surveydetails': '/enquetedetails',
        'fillsurvey': '/enquete',
        'customersatisfaction': '/klanttevredenheid',
        'home': '/',
        'employees': '/werknemer',
        'login': '/login',
        'forgotpassword': '/wachtwoordvergeten',
        'setpassword': '/login/wijzigwachtwoord',
        'editprofile': '/bewerkprofiel',
        'settings': '/instellingen',
        'settings_documentnotifications': '/instellingen/medewerker-data',
        'settings_cao': '/instellingen/cao',
        'settings_holidays': '/instellingen/vrije-dagen',
        'settingsaccountview': '/accountview',
        'projects': '/projecten',
        'timeregistration': '/uren',
        'leave': '/verlof',
        'leaverequest': '/verlofaanvraag',
        'leaveoverview': '/verlofoverzicht',
        'adminleaverequest': '/verlofs',
        'admindashboard': '/dashboard',
        'employeedashboard': '/employeedashboard',
        'employeehours': '/employeehours',
        'employeefiles': '/dossier',
        'submittedhours': '/hours/ingediendeuren',
        'unsubmittedhours': '/hours/nietingedienduren',
        'timeshare': '/gedeeldetijd',
        'surveythankyou': '/danku',
        'employeenotification': '/meldingen',
        'employeeholidays': '/vrije-dagen',
        'settings_administrator': '/instellingen/beheerder',
        'smslog': '/smslogboek',
        'reports': '/rapportage',
        'reportprojecthours': '/rapportage/projecturen',
        'reportuserhours': '/rapportage/gebruikersuren',
        'settingsdocuments': '/instellingen/documenten'
    },
    'success': {
        'saveChanges': 'De gegevens zijn succesvol opgeslagen',
        'deleteRecord': 'De gegevens zijn succesvol verwijderd',
        'updatepassword': 'Uw wachtwoord is ingesteld. U kunt nu inloggen',
        'resetpassword': 'Reset wachtwoordlink verzonden naar uw e-mailadres!',
        'sentmail': 'Mail succesvol verzonden!',
        'thankyou': '<p>Uw betaling is door ons ontvangen. </p><p>De factuur wordt per email naar u toegestuurd.</p><p>Wij zullen uw opdracht zo spoedig mogelijk verwerken.</p>',
        'newslettersent': 'Nieuwsbrief succesvol verzonden!',
        'register': 'De registratie is succesvol voltooid',
        'unregister': 'De registratie is succesvol ongedaan gemaakt',
        'createinvoice': 'De verkoopfactuur is aangemaakt in Exact',
        'merge': 'Het samenvoegen is voltooid',
        'synccarerix': 'Gesynchroniseerd met Carerix',
        'synctimechimp': 'Gesynchroniseerd met TimeChimp',
        'disapprove': 'De factuur is afgekeurd',
        'approve': 'De factuur is goedgekeurd',
        'surveythankyou': 'Uw feedback is succesvol verzonden!',


    },
    'status': {
        'status': 'Status',
        'loading': 'Laden...',
        'sending': 'Bezig met verzenden...',
        'norecords': 'Geen data gevonden',
        'loginfailed': 'Ongeldige combinatie van gebruikersnaam en wachtwoord',
        'invalidpassword': 'Het wachtwoord is ongeldig',
        'tokenexpired': 'Uw token voor het opnieuw instellen van uw wachtwoord is verlopen',
        'paymentfail': 'Betaling mislukt',
        'paymentcancel': 'Betaling geannuleerd',
        'paymentexpired': 'Betalingsantwoord is verlopen',
        'linkexpired': 'Deze link is verlopen'
    },
    'subtitle': {
        'edit': 'Bewerk',
        'employersavedfilter': 'Uw opgeslagen zoekfilters',
        'joboptions': 'Selecteer eventuele extra vacature-opties',
        'employees': 'Een overzicht van medewerkers uit Carerix en TimeChimp',
        'companies': 'Een overzicht van relaties uit Carerix en TimeChimp',
        'jobs': 'Een overzicht van plaatsingen uit Carerix en TimeChimp',
        'invoicelines': 'Een overzicht van factuurregels uit Carerix',
        'settingsexact': 'Instellingen voor de koppeling met Exact',
        'permanentplacements': 'Permanente plaatsingen uit Carerix',
        'contractors': 'Contractors uit Carerix en TimeChimp',
        'settingsaccountview': 'Instellingen voor de koppeling met AccountView',
        'incominginvoice': 'Een overzicht van inkoopfacturen'
    },
    'periods': {
        'short': {
            'sunday': 'Zo',
            'monday': 'Ma',
            'tuesday': 'Di',
            'wednesday': 'Wo',
            'thursday': 'Do',
            'friday': 'Vr',
            'saturday': 'Za',
            'january': 'Jan',
            'february': 'Feb',
            'march': 'Maa',
            'april': 'Apr',
            'may': 'Mei',
            'june': 'Jun',
            'july': 'Jul',
            'august': 'Aug',
            'september': 'Sep',
            'october': 'Okt',
            'november': 'Nov',
            'december': 'Dec',
        },
        'long': {
            'january': 'Januari',
            'february': 'Februari',
            'march': 'Maart',
            'april': 'April',
            'may': 'Mei',
            'june': 'Juni',
            'july': 'Juli',
            'august': 'Augustus',
            'september': 'September',
            'october': 'Oktober',
            'november': 'November',
            'december': 'December',
        },
        'week': 'Week',
        'month': 'Maand'
    },
    'enum': {
        'documentnotificationtype': {
            'Registration': 'Registratie',
            'Expiration': 'Met einddatum',
            'YearlyRecurring': 'Jaarlijks terugkerend',
        },
        'timeregistrationtype': {
            'Automatic': 'Automatisch',
            'PerProject': 'Per project',
        },
        'employmenttype': {
            'FullTime': 'Full time',
            'PartTime': 'Part time',
        },
        'leavestatus': {
            'Requested': 'Aangevraagd',
            'Approved': 'Goedgekeurd',
            'Denied': 'Afgekeurd'
        },
        'leavetype': {
            "DayPart": "Dagdeel",
            "Day": "Dag"
        },
        'weekstatus': {
            "Open": "Open",
            "Close": "Gesloten"
        }
    },
    'title': {
        'administrator': 'Beheerder',
        'fillsurvey': 'Vul enquête in',
        'questionnaire': 'vragenlijst',
        'customersatisfaction': 'Klanttevredenheid',
        'addnewrecord': 'Nieuw record toevoegen',
        'dashboard': 'Dashboard',
        'document': 'Document',
        'documentnotifications': 'Medewerker data',
        'documentnotificationtype': 'Soort',
        'durationDocument': 'Geldigheidsdocumenten',
        'changepassword': 'Wijzig wachtwoord',
        'editprofile': 'Bewerk profiel',
        'employees': 'Werknemers',
        'home': 'Home',
        'hours': 'Uren',
        'manage': 'Beheer',
        'navigation': 'Menu',
        'setpassword': 'Stel een wachtwoord in',
        'updatepassword': 'Wijzig wachtwoord',
        'testenvironment': 'TESTVERSIE',
        'log': 'Logs',
        'leave': 'Verlof',
        'leaverequest': 'Aanvraag',
        'leaveoverview': 'Overzicht',
        'notes': 'Notities',
        'details': 'Details',
        'users': 'Gebruikers',
        'settings': 'Instellingen',
        'settingsaccountview': 'AccountView',
        'cao': 'CAO',
        'caodefinations': 'Definitie collectieve arbeidsovereenkomst',
        'timeregistrationtype': 'Type tijdregistratie',
        'holidays': 'Verplichte vrije dagen',
        'projects': 'Projecten',
        'portal': 'Urenregistratie',
        'personalDocument': 'Persoonlijke documenten',
        'time': 'Uren',
        'employeefiles': 'Dossier',
        'sharetimeregistration': 'Weekstaat delen met collega',
        'smslog': 'Sms logboek',
        'addressData': 'Adresgegevens',
        'contactData': 'Contactgegevens',
        'submittedhours': 'Ingediende uren',
        'unsubmittedhours': 'Niet ingediende uren',
        'userhours': 'Gebruikersuren',
        'timeregistrationshare': 'Gedeelde weekstaat',
        'overview': 'Overzicht',
        'employeenotification': 'Meldingen',
        'reports': 'Rapportage',
        'projecthours': 'Projecturen',
        'documents': 'Documenten',
        'generalDocument': 'Algemene documenten',
        'settingsdocuments': 'Algemene documenten'
    },
    'warnings': {
        'leavereject': 'De datum van je verlofaanvraag ligt binnen nu en 3 weken. Het kan zijn dat de aanvraag om deze reden afgekeurd wordt.',
        'closeprevweek': 'De vorige week is nog niet ingediend.',
        'approveweek': 'Week is al goedgekeurd.',
        'notvaliduser': 'Tijd wordt niet gedeeld met de huidige gebruiker.',
        'recordexists': 'Deze gegevens zijn al verwerkt.',
        'definationnotexistsforyear':'Definitie bestaat niet voor jaar vriendelijk toevoegen!'
    },
    'customercity': 'Plaats',
    'customeremailaddress': 'E-mailadres',
    'customerpostalcode': 'Postcode',
    'customeraddress': 'Adres',
    'details': 'gegevens',
    'document': 'Document',
    'averagescore': 'Gemiddelde score',
    'link': 'Koppeling',
    'subject': 'Onderwerp',
    'customername': 'Naam klant',
    'access': 'Toegang',
    'active': 'Actief',
    'activedays': 'Actieve Dagen',
    'address': 'Adres',
    'administration': 'Administratie',
    'administrator': 'Beheerder',
    'approved': 'Goedgekeurd',
    'all': 'Alle',
    'amount': 'Bedrag',
    'and': 'en',
    'businessdays': 'Werkdagen',
    'cao': 'CAO',
    'city': 'Plaats',
    'code': 'Code',
    'colleague': 'Collega',
    'confirmpassword': 'Bevestig',
    'contract': 'Contract',
    'constructionhours': 'Bouw Uren',
    'createdon': 'Gemaakt op',
    'currentpassword': 'Huidig wachtwoord',
    'currentrequest': 'Huidige aanvraag',
    'day': 'Dag | Dagen',
    'date': 'Datum',
    'dashboard': 'dashboard',
    'dateofbirth': 'Geboortedatum',
    'description': 'Omschrijving',
    'difference': 'Verschil',
    'donotdeduct': 'Niet afboeken',
    'email': 'E-mailadres',
    'emailaddress': 'E-mailadres',
    'employees': 'Werknemers',
    'employmenttype': 'Type contract',
    'enddate': 'Einddatum',
    'endtime': 'Eindtijd',
    'expirydate': '(Verval)datum',
    'excludedemployees': 'Uitgesloten medewerkers',
    'expirationreminderthreshold': 'Herinneringen in dagen',
    'firstname': 'Voornaam',
    'fines': 'Boetes',
    'forgotpassword': 'Wachtwoord vergeten?',
    'from': 'Van',
    'fte': 'Werktijdsfactor',
    'gender': 'Geslacht',
    'general': 'Algemeen',
    'holidays': 'Vrije dagen',
    'hours': 'Uren',
    'initials': 'Voorletters',
    'inactive': 'Alles',
    'isfree': 'Is gratis',
    'islimitedadministrator': 'Beperkte beheerder',
    'isTimeRegistrationOnly': 'Uitsluitend urenregistratie',
    'ispupil': 'Is leerling',
    'jobtitle': 'Functie',
    'language': 'Taal',
    'lastname': 'Achternaam',
    'leavehours': 'Verlofuren',
    'leaverequest': 'Verlofaanvragen',
    'leavetype': 'Type verlof',
    'isshortabsence': 'Is kort verzuim',
    'message': 'Bericht',
    'middlename': 'Tussenvoegsel',
    'mondayactive': 'Maandag',
    'tuesdayactive': 'Dinsdag',
    'wednesdayactive': 'Woensdag',
    'thursdayactive': 'Donderdag',
    'fridayactive': 'Vrijdag',
    'saturdayactive': 'Zaterdag',
    'sundayactive': 'Zondag',
    'name': 'Naam',
    'newpassword': 'Nieuw wachtwoord',
    'notes': 'Notities',
    'number': 'Personeelsnummer',
    'noofhours': 'Aantal uren',
    'notapproved': 'Niet goedgekeurd',
    'notsubmitted': 'Niet ingezonden',
    'employeenotification': 'Meldingen',
    'overtimehours': 'Overuren',
    'phonenumber': 'Telefoonnummer',
    'password': 'Wachtwoord',
    'passwordRequirements': 'Het wachtwoord dient minimaal 6 tekens lang te zijn en minimaal: 1 hoofdletter, 1 kleine letter, 1 getal en een speciaal teken (b.v. ! @ # $ % ^ & * _ -) te bevatten.',
    'partnername': 'Naam partner',
    'partnerphone': 'Telefoonnummer partner',
    'datemarried': 'Trouwdatum',
    'period': 'Periode',
    'percentageoffirstyear': 'Percentage van het eerste jaar',
    'postalcode': 'Postcode',
    'preposition': 'Voorzetsel',
    'project': 'Project',
    'projects': 'Projecten',
    'pupilhours': 'Leerling uren',
    'reason': 'Reden',
    'response': 'Reactie',
    'region': 'Standplaats',
    'remarks': 'Opmerkingen',
    'remaining': 'Restant',
    'schedulefreehours': 'Roostervrije uren',
    'sdworksnumber': 'Sdworks nummer',
    'shortabsencehours': 'Kort verzuim',
    'selectfile': 'Selecteer bestand',
    'sendinvitation': 'Stuur uitnodigingsmail',
    'sendpassword': 'Verstuur e-mail',
    'send': 'Verzend Mail',
    'sent': 'Verzonden',
    'service12': '12,5 jaar in dienst',
    'service20': '20 jaar in dienst',
    'service25': '25 jaar in dienst',
    'setemployeement':'Personeelsbestand instellen',
    'married12': '12.5 jaar getrouwd',
    'married25': '25 jaar getrouwd',
    'salarycomponent': 'Salariscomponent',
    'selectperiod': 'Periode',
    'setpassword': 'Stel een wachtwoord in',
    'settings': 'Instellingen',
    'sickhours': 'Ziekteuren',
    'smscredit': 'Sms-tegoed',
    'smslog': 'Sms logboek',
    'startdate': 'Startdatum',
    'starttime': 'Begintijd',
    'street': 'Straat',
    'streetnumber': 'Huisnummer',
    'submitted': 'Ingezonden',
    'time': 'Uren',
    'timehours': 'Weekstaten',
    'timesavingshours': 'Tijdspaarfonds',
    'timeregistrationshare': 'Gedeelde weekstaat',
    'travelhours': 'Reistijd',
    'type': 'Soort',
    'to': 'Aan',
    'total': 'Totaal',
    'usedbefore': 'Reeds opgenomen',
    'unpaidleave': 'Onbetaald verlof',
    'userhours': 'Gebruikersuren',
    'utahours': 'Uta Uren',
    'weekclosed': 'Deze week is gesloten',
    'workhours': 'Werkuren',
    'yes': 'Ja',
    'year': 'Jaar',
    'employee': 'Naam',
    'week': 'Week',
    'rank': 'Score',
    'weeks-ago': 'Aantal weken terug'
};

export default nl;
